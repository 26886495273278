
<script>
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";
import DailyCapacities from '@/components/widgets/dailyCapacities';

export default {
  components: {
    DailyCapacities
  },
  data() {
    return {
      showModal: false,
      state: null,
      title: "Edit installer",
      installerForm: {
        id: null,
        idPublic: null,
        name: null,
        code: null,
        phoneNumber: null,
        dailyCapacities: []
      },
      installerFormSubmit: false,
      installerRef: null,
      isLoading: false
    };
  },
  created(){
    this.$bus.$on('open-edit-installer-dialog', this.openEditInstallerDialog)   
    this.$bus.$on('open-create-installer-dialog', this.openCreateInstallerDialog)   
  },
  beforeDestroy(){
    this.$bus.$off('open-edit-installer-dialog', this.openEditInstallerDialog)  
    this.$bus.$off('open-create-installer-dialog', this.openCreateInstallerDialog)   
  },    
  computed: {

  },
  methods: {
    openEditInstallerDialog: function(installer){
      this.state = "edit"
      this.installerRef = installer;
      this.showModal = true;

      //load installer
      CalendarApiService.getInstaller(installer.idPublic).then((response) => {
        this.installerForm = response.data.data;
      })
    },
    openCreateInstallerDialog(){
      this.state = "create"
      this.showModal = true;

    },
    closeModal(){
      this.showModal = false;
      this.installerFormSubmit = false;
      this.installerForm = {
        id: null,
        idPublic: null,
        name: null,
        code: null,
        phoneNumber: null,
        dailyCapacities: []
      };

      this.$emit('close-installer-modal')
    },
    submit(){
      this.installerFormSubmit = true;
      this.$v.installerForm.$touch()

      if (!this.$v.installerForm.$invalid) {
        //save form
        this.isLoading = true;

        if(this.state == "edit"){
          CalendarApiService.updateInstaller(this.installerForm).then(() => {
            this.isLoading = false;
            this.$emit('installer-updated')
            this.closeModal();
          }, () => { this.isLoading = false; })
        }else if(this.state == "create"){
          CalendarApiService.createInstaller(this.installerForm).then(() => {
            this.isLoading = false;
            this.$emit('installer-created')
            this.closeModal();
          }, () => { this.isLoading = false; })
        }

      }
    }
  },
  validations: {
    installerForm: {
      name: {required},
      code: {required}
    }
  } 
};
</script>

<template>
  <b-modal
    v-model="showModal"
    :title="state == 'edit' ? $t('general.editInstaller') : $t('general.createInstaller')"
    title-class="text-black font-18"
    hide-footer
    body-class="p-4"
    size="lg"
  >
      <loading :active.sync="isLoading" 
      :can-cancel="false"
      color='#f1b44c'
      :width=70
      :height=70
      loader='dots'
      :is-full-page="false">
    </loading>  
    <b-form @submit.prevent="submit">   
      <div class="row">
        <b-form-group style="position:relative;" class="col-12">
          <label>{{$t('general.name')}}</label>
          <b-form-input 
            v-model="installerForm.name"
            :class="{ 'is-invalid': installerFormSubmit && $v.installerForm.name.$error }"
            :placeholder="$t('general.name')">
          </b-form-input>
          <div v-if="installerFormSubmit && $v.installerForm.name.$error" class="invalid-feedback">
            <span v-if="!$v.installerForm.name.required">{{$t('general.requiredValue')}}</span>
          </div>
        </b-form-group>

        <b-form-group style="position:relative;" class="col-12">
          <label>Code</label>
          <b-form-input 
            v-model="installerForm.code"
            :class="{ 'is-invalid': installerFormSubmit && $v.installerForm.code.$error }"
            placeholder="Code">
          </b-form-input>
          <div v-if="installerFormSubmit && $v.installerForm.code.$error" class="invalid-feedback">
            <span v-if="!$v.installerForm.code.required">{{$t('general.requiredValue')}}</span>
          </div>
        </b-form-group>  

        <b-form-group style="position:relative;" class="col-12">
          <label>{{$t('general.phoneNumber')}}</label>
          <b-form-input 
            v-model="installerForm.phoneNumber"
            :placeholder="$t('general.phoneNumber')">
          </b-form-input>
        </b-form-group>               

        <!-- //DAILY CAPACITIES -->
        <DailyCapacities v-model="installerForm.dailyCapacities"></DailyCapacities>

      </div>
      <div class="text-right pt-3">
        <b-button variant="light" @click="closeModal">{{$t('general.close')}}</b-button>
        <b-button type="submit" class="ml-1" variant="success" >{{$t('general.save')}}</b-button>
      </div>
    </b-form>
  </b-modal>
</template>