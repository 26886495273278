<script>
import { required } from "vuelidate/lib/validators";
// eslint-disable-next-line no-unused-vars
import { cloneDeep, tap, set } from 'lodash'

export default {
  components: {
  },  
  props: ['value'],
  data(){
    return {
      capacityForm: {
        measureType: null,
        min: null,
        max: null
      },
      capacitySubmit: false,
    }
  },
  created(){
    
  },
  computed: {
  },  
  methods:{
    submitCapacityForm(){
      this.capacitySubmit = true;
      this.$v.capacityForm.$touch()

      if (!this.$v.capacityForm.$invalid) {
        this.capacitySubmit = false;

        let copy = tap(cloneDeep(this.value), (v) => {v.push({
          measureType: this.capacityForm.measureType,
          min: this.capacityForm.min,
          max: this.capacityForm.max
        })})

        this.$emit('input', copy)

        //reset form
        this.capacityForm.measureType = null;
        this.capacityForm.min = null
        this.capacityForm.max = null
      } 
    },  
    // eslint-disable-next-line no-unused-vars
    deleteCapacity(index){
      let copy = tap(cloneDeep(this.value), (v) => { v.splice(index, 1) })

      this.$emit('input', copy)
    },
    updateCapacity(index, key, value){

      let copy = tap(cloneDeep(this.value), (v) => { v[index][key] = value })

      this.$emit('input', copy)
    }
  } ,
  validations: {
    capacityForm: {
      measureType: { required },
      min: { required },
      max: { required }
    }
  },    
};
</script>

<template>
  <div class="col-12">
    <h4 class="mt-2 mb-2">{{$t('general.dailyCapacities')}}</h4>
    
    <form class="mb-4" @submit.prevent="submitCapacityForm">
      <div class="row zno-gutters" style="max-width:700px; align-items:flex-end;">
        <div class="col-12 col-md-6 mt-2">
          <div class="form-group mb-0"  style="position:relative; top:2px;">
            <label>{{$t('general.capacity')}}</label>
            <multiselect 
            :validator="$v.capacityForm.measureType" 
            :class="{ 'is-invalid': capacitySubmit && $v.capacityForm.measureType.$error }"
            style="width:100%;" 
            v-model="capacityForm.measureType" 
            :options="measureTypes" 
            :placeholder="$t('general.selectDailyCapacity')"
            :show-labels="false">
              <template slot="singleLabel" slot-scope="props">
                <span >{{ $t('general.' + props.option) }}</span>
              </template>
              <template slot="option" slot-scope="props">
                <span >{{ $t('general.' + props.option)}}</span>
              </template>
            </multiselect>
            <div v-if="capacitySubmit && $v.capacityForm.measureType.$error" class="invalid-tooltip">
              <span v-if="!$v.capacityForm.measureType.required">{{$t('general.requiredValue')}}</span>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-2 mt-2">
          <b-form-group class="mb-0" style="position:relative;">
            <label>{{$t('general.min')}}</label>
            <b-form-input 
              type="number" step="0.01"
              :class="{ 'is-invalid': capacitySubmit && $v.capacityForm.min.$error }"
              style="max-width:90px;" 
              v-model="capacityForm.min" 
              placeholder="Min" ></b-form-input>
            <div v-if="capacitySubmit && $v.capacityForm.min.$error" class="invalid-tooltip">
              <span v-if="!$v.capacityForm.min.required">{{$t('general.requiredValue')}}</span>
            </div> 
          </b-form-group>   
        </div>


        <div class="col-12 col-md-2 mt-2">
          <b-form-group class="mb-0" style="position:relative;">
            <label>{{$t('general.max')}}</label>
            <b-form-input 
              type="number" step="0.01"
              :class="{ 'is-invalid': capacitySubmit && $v.capacityForm.max.$error }"
              style="max-width:90px;" 
              v-model="capacityForm.max" 
              placeholder="Max" ></b-form-input>
            <div v-if="capacitySubmit && $v.capacityForm.max.$error" class="invalid-tooltip">
              <span v-if="!$v.capacityForm.max.required">{{$t('general.requiredValue')}}</span>
            </div> 
          </b-form-group>  
        </div>

        <div class="col-12 col-md-2 mt-2">
          <b-button style="height:36px;" type="submit"  variant="primary" >{{$t('general.add')}}</b-button>
        </div>
      </div>

    </form>
    <div class="table-responsive">
      <table class="table mb-0">
        <thead class="thead-light">
          <tr>
            <th>{{$t('general.capacity')}}</th>
            <th style="text-align:center;">{{$t('general.min')}}</th>
            <th style="text-align:center;">{{$t('general.max')}}</th>
            <th style="width:100px; text-align:center;">{{$t('general.delete')}}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(capacity, index) in value" :key="index">
            <td>{{$t('general.' + capacity.measureType)}}</td>
            <td >
              <b-form-input
                required
                type="number" step="0.01"
                :value="capacity.min"
                @input="(e) => {updateCapacity(index, 'min', e)}"
                placeholder="0"
                style="max-width:90px; margin:0 auto;">
              </b-form-input>
            </td>
            <td >
              <b-form-input 
                required
                type="number" step="0.01"
                :value="capacity.max"
                @input="(e) => {updateCapacity(index, 'max', e)}"
                placeholder="0"
                style="max-width:90px; margin:0 auto;">
              </b-form-input>
            </td>
            <td @click="deleteCapacity(index)" style="text-align:center; color: #f46a6a; font-size:1.8em;"><i style="cursor:pointer;" class="mdi mdi-trash-can-outline"></i></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>