<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import CalendarApiService from "@/services/calendarService";
import { required } from "vuelidate/lib/validators";
import InstallerDetailModal from "./modals/installerDetail"
import DeletionModal from "../../../../components/widgets/deletion-modal.vue"

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "installers",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    InstallerDetailModal,
    DeletionModal
  },
  data() {
    return {
      title: "Installers",
      isLoading: false,
      items: [
        {
          text: "installers",
          href: "/installers",
        },
        {
          text: "",
          active: true,
        },
      ],
      installers: [],
      installerSubmit: false,
      installerForm: {
        name: null,
        code: null,
        phoneNumber: null,
      },
      installerIdPublic: null,
      installerIndex: null
    };
  },
  created (){
    //Load installers
    this.isLoading = true;
    this.loadInstallers();
  },
  mounted() {},
  methods: {
    addJobType(){

    },
    showDeletionModal(idPublic, index) {
      this.$refs.installerDeleteModal.show().then(() => {
        this.isLoading = true;
        CalendarApiService.deleteInstaller(idPublic).then(() => {
          this.installers.splice(index, 1);
          this.isLoading = false;
        })
      })
    },
    loadInstallers(){
      CalendarApiService.getInstallers().then((response) => {
        this.installers = response.data.data;
        this.isLoading = false;
      })
    },
    submitInstaller(){
      this.installerSubmit = true;
      this.$v.installerForm.$touch()

      if (!this.$v.installerForm.$invalid) {
        this.isLoading = true;
        CalendarApiService.createInstaller(this.installerForm).then((response) => {
          let installer = response.data.data;
          let model = {
            idPublic: installer.idPublic,
            name: this.installerForm.name,
            code: this.installerForm.code,
            phoneNumber: this.installerForm.phoneNumber,
          };
          this.installers.push(model);
          this.isLoading = false;

          //reset form
          this.installerForm.name = null;
          this.installerForm.code = null;
          this.installerForm.phoneNumber = null;
          this.installerSubmit = false;
        })
      }       
    },
    editInstaller(installer){
      this.$bus.$emit('open-edit-installer-dialog', installer);
    },
    installerUpdated(){
      this.loadInstallers();
    },
    createInstaller(){
      this.$bus.$emit('open-create-installer-dialog');
    },
    installerCreated(){
      this.loadInstallers();
    }    
  },
  validations: {
    installerForm: {
      name: { required },
      code: { required }
    }
  }  
};
</script>

<template>
  <Layout>  
    <PageHeader :title="$t('general.installers')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3" style="display:flex;">
                <p class="card-title-desc mb-0">
                  {{$t('general.installersList')}}
                </p> 
                <b-button style="margin-left:auto;" @click="createInstaller" variant="primary" >{{$t('general.addInstaller')}}</b-button>
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading :active.sync="isLoading" 
                :can-cancel="false"
                color='#f1b44c'
                :width=70
                :height=70
                loader='dots'
                :is-full-page="false">
              </loading>  
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th>{{$t('general.name')}}</th>
                    <th style="text-align:center;">Code</th>
                    <th style="text-align:center; width:200px;">{{$t('general.phoneNumber')}}</th>
                    <th style="width:100px; text-align:center; width:200px;">{{$t('general.action')}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(installer, index) in installers" :key="index">
                    <td>{{installer.name}}</td>
                    <td style="text-align:center; ">{{installer.code}}</td>
                    <td style="text-align:center;">{{installer.phoneNumber}}</td>
                    <td style="text-align:center;">
                      <b-dropdown dropleft size="lg" class="card-drop" variant="link" toggle-class="text-decoration-none p-0" no-caret>
                        <template #button-content >
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="editInstaller(installer)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i> {{$t('general.edit')}}
                        </b-dropdown-item>

                        <b-dropdown-item @click="showDeletionModal(installer.idPublic, index)">
                          <i class="fas fa-trash-alt text-danger mr-1"></i> {{$t('general.delete')}}
                        </b-dropdown-item>
                      </b-dropdown>                      
                    </td>                      
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <DeletionModal ref="installerDeleteModal" :content="this.$t('general.deleteInstallerContent')" :title="this.$t('general.deleteInstallerTitle')"/>
    <InstallerDetailModal @installer-updated="installerUpdated" @installer-created="installerCreated"/>
  </Layout>
</template>
